<template>
  <div class="page">
    <div class="img-row">
      <img :src="imgUrl" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'ZzImage',
  computed: {
    imgUrl() {
      return this.$route.query?.imageUrl || '';
    },
    name() {
      return this.$route.query?.name || '';
    },
  },
  mounted() {
    document.title = this.name;
  },
};
</script>
<style lang="scss" scoped>
.img-row {
  width: 100%;
  margin: auto;
  img {
    width: 100%;
  }
}
</style>
